import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Styles from "./css/404.module.css"

const NotFoundPage = () => (
  <Layout>

    <h1 className={Styles.title}>PAGE NOT FOUND</h1>
    <p className={Styles.text}>This page does not exist!</p>

    <div className={Styles.wrapper}>
      <Link to="/" ><button className={Styles.butto}>Click here to return to home</button></Link>
    </div>
  </Layout>
)

export default NotFoundPage
